import React from "react";
import { Modal } from "react-bootstrap";
import { IoIosClose } from "react-icons/io";
import ArrowRight from "../../../../assets/images/arrowRight.svg";

const EditConfirmationModal = ({ showEditConfirmationModal, editConfirmationModalClose, handleEditConfirmation }) => {
  return (
    <Modal
      className="pause delete-confirmation"
      centered
      show={showEditConfirmationModal}
      onHide={editConfirmationModalClose}
    >
      <Modal.Header className="border-0 justify-content-end">
        <button onClick={editConfirmationModalClose} className="text-white p-0 btn">
          <IoIosClose size="30px" />
        </button>
      </Modal.Header>
      <Modal.Body>
        <p>
          This action will trigger a <span>smart contract</span> integration. This will initiate a{" "}
          <span>transaction</span>.
          <br />
          Are you sure you want to continue?
        </p>
      </Modal.Body>
      <Modal.Footer className="justify-content-start border-0">
        <button
          onClick={() => {
            handleEditConfirmation();
            editConfirmationModalClose();
          }}
          className="btn next-btn m-0"
        >
          Confirm
          <span className="d-flex">
            <img src={ArrowRight} alt="Icon" />
          </span>
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default EditConfirmationModal;
