import React, { useEffect, useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { Modal } from "react-bootstrap";
import { FormProvider, useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import ArrowRight from "../../../../assets/images/arrowRight.svg";
import { setLoader } from "../../../../store/reducer";
import { ApiCall } from "../../../../utils/ApiUtils";
import { editMintPriceSchema } from "../../../home/validationSchema";
import MintConfirmationModal from "./MintConfirmationModal";
import { getMaxSupply, getSaleConditions, updateSaleCondition } from "../../../../web3.js/contractIngration";
import { ethers } from "ethers";
import { errorMessages } from "../../../../utils/errorMessages";
import EditConfirmationModal from "./EditConfirmationModal";

const EditMintPrice = ({
  editModal,
  editModalClose,
  groupName,
  mintingMode,
  confirmMintModal,
  confirmMintModalShow,
  confirmMintModalClose,
  handleConfirmMint,
  dataItem,
  groupItem,
  fetchData,
  setMiningMode,
}) => {
  const dispatch = useDispatch();

  const forgeId = dataItem.id;

  const [showEditConfirmationModal, setShowEditConfirmationModal] = useState(false);
  const [freezePrize, setFreezePrize] = useState(false);
  const [maxSupply, setMaxSupply] = useState(0);

  const methods = useForm({
    resolver: yupResolver(editMintPriceSchema),
    defaultValues: {},
    mode: "all",
  });

  useEffect(() => {
    if (groupItem.price >= 0) setValue("price", groupItem.price);
    if (groupItem.limitPerWallet) setValue("limitPerWallet", groupItem.limitPerWallet);
    if (groupItem.maxAllocated) setValue("maxAllocated", groupItem.maxAllocated);

    const getData = async () => {
      const sale = await getSaleConditions(groupItem.id);
      if (sale && sale?.totalTokenMinted?.toNumber() > 0) {
        setFreezePrize(true);
      }

      const supply = await getMaxSupply();
      setMaxSupply(parseFloat(supply));
    };

    getData();
  }, [groupItem]);

  const {
    register,
    handleSubmit,
    formState: { errors, touchedFields },
    watch,
    setValue,
    setError,
    clearErrors,
  } = methods;

  const maxAllocated = watch("maxAllocated");

  const handleOnChange = (e) => {
    const value = e.target.value;
    setValue("maxAllocated", value, { shouldTouch: true });

    if (!/^[1-9][0-9]*$/.test(Number(value))) {
      setError("maxAllocated", { type: "custom", message: "Number cannot be zero or negative." });
    } else if (parseFloat(value) > maxSupply - parseFloat(dataItem.ownerReserve)) {
      setError("maxAllocated", {
        type: "custom",
        message: "Max Allocated supply cannot be greater total number of NFT's that can be minted",
      });
    } else {
      clearErrors("maxAllocated");
    }
  };

  const onSubmit = async (formData) => {
    try {
      dispatch(setLoader(true));

      await updateSaleCondition(
        [
          true,
          formData.limitPerWallet,
          formData.maxAllocated,
          1,
          ethers.utils.parseEther(formData.price?.toString()),
          groupItem.id,
          "0x0000000000000000000000000000000000000000",
        ],
        groupItem.id
      );
      await ApiCall("PUT", "/rest/forge/pricing/" + forgeId, {
        groupId: groupItem.id,
        allowListGroup: [{ ...groupItem, ...formData }],
      });
      if (mintingMode) {
        confirmMintModalShow();
        setMiningMode(false);
      }
      fetchData();
      editModalClose();
      dispatch(setLoader(false));
      toast.success("Changes saved!");
    } catch (error) {
      dispatch(setLoader(false));
      console.log(error);
      toast.error(errorMessages[error?.code] || error?.response?.data?.message || "Something went wrong.");
    }
  };

  const getRequiredError = (err) => {
    for (let [_, value] of Object.entries(Array.isArray(err) ? err[0] || {} : {})) {
      dispatch(setLoader(false));
      if (value?.type === "required") {
        toast.error("Fields marked by a red dot are required.");
        break;
      } else {
        if (Array.isArray(value)) {
          getRequiredError(value);
        }
      }
    }
  };

  const handleFormSubmit = async () => {
    await handleSubmit(onSubmit)();
    getRequiredError(methods.formState.errors);
  };

  return (
    <>
      <Modal className="edit-modal" size="lg" centered show={editModal} onHide={editModalClose}>
        <FormProvider {...methods}>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              setShowEditConfirmationModal(true);
            }}
          >
            <Modal.Header closeButton className="border-0">
              <h2 className="mb-0">
                <b>{groupName}</b>
              </h2>
            </Modal.Header>
            <Modal.Body>
              <p className="futura-light">Configure the pricing for this list.</p>
              <div className="inside-div-cm show-main-register-mint mb-0">
                <div className="comon-input-div mt-2">
                  <div className={`form-group ${errors.price?.message ? "form-error" : ""}`}>
                    <label style={{ opacity: freezePrize ? 0.5 : 1 }}>Price</label>
                    <div className="inpy-div d-flex mt-2 form-group">
                      <input
                        name={`price`}
                        type="text"
                        className="mb-0 w-100 form-control"
                        {...register("price")}
                        disabled={freezePrize}
                      />
                      <div className="slp">
                        <span className="form-select" style={{ opacity: freezePrize ? 0.5 : 1 }}>
                          ETH
                        </span>
                      </div>
                    </div>
                    {errors.price && errors.price?.type !== "required" && (
                      <div className="error-text mt-1">
                        <span className="info">i</span>
                        <span>{errors.price.message}</span>
                      </div>
                    )}
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12 form-group">
                    <div className={`form-group ${errors.maxAllocated?.message ? "form-error" : ""}`}>
                      <label>Max Allocated Supply</label>
                      <input
                        name={`maxAllocated`}
                        type="text"
                        className="mb-0 w-100 form-control"
                        value={maxAllocated}
                        onChange={handleOnChange}
                      />
                      {errors.maxAllocated && errors.maxAllocated?.type !== "required" && (
                        <div className="error-text mt-1">
                          <span className="info">i</span>
                          <span>{errors.maxAllocated.message}</span>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12 form-group">
                    <div className={`form-group ${errors.limitPerWallet?.message ? "form-error" : ""}`}>
                      <label>Limit Per Wallet</label>
                      <input
                        name={`limitPerWallet`}
                        type="text"
                        className="mb-0 w-100 form-control"
                        {...register("limitPerWallet")}
                      />
                      {errors.limitPerWallet && errors.limitPerWallet?.type !== "required" && (
                        <div className="error-text mt-1">
                          <span className="info">i</span>
                          <span>{errors.limitPerWallet.message}</span>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer className="justify-content-end border-0">
              <button
                type="submit"
                className="btn next-btn m-0"
                disabled={Object.keys(errors).length > 0 || Object.keys(touchedFields).length === 0}
              >
                Done
                <span className="d-flex">
                  <img src={ArrowRight} alt="Icon" />
                </span>
              </button>
            </Modal.Footer>
          </form>
        </FormProvider>
      </Modal>
      {confirmMintModal && (
        <MintConfirmationModal
          confirmMintModal={confirmMintModal}
          confirmMintModalClose={confirmMintModalClose}
          handleConfirmMint={handleConfirmMint}
          isUpdate={editModal}
        />
      )}
      {showEditConfirmationModal && (
        <EditConfirmationModal
          showEditConfirmationModal={showEditConfirmationModal}
          editConfirmationModalClose={() => setShowEditConfirmationModal(false)}
          handleEditConfirmation={handleFormSubmit}
        />
      )}
    </>
  );
};

export default EditMintPrice;
