import React, { useEffect, useState } from "react";
import { FormProvider, useFieldArray, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import useFormPersist from "react-hook-form-persist";
import { useNavigate } from "react-router-dom";

import FormInput from "../../../components/FormInput";
import ArrowRight from "../../../assets/images/arrowRight.svg";
import { secondStepValidation } from "../validationSchema";
import { ApiCall } from "../../../utils/ApiUtils";
import {
  setCurrentFormStep,
  setLoader,
  setSavedFirstStep,
  setSaveDraftData,
  setSkeleton,
} from "../../../store/reducer";
import DeploymentModal from "./DeploymentModal";
import { makeTransaction } from "../../../web3.js/contractIngration";
import { errorMessages } from "../../../utils/errorMessages";

function PricingGroupsForm({ goNext }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const selectedAllowlistGroups = useSelector(({ selectedAllowlistGroups }) => selectedAllowlistGroups);
  const savedFirstStep = useSelector(({ savedFirstStep }) => savedFirstStep);

  const [show, setShow] = useState(false);
  const [deploymentFee, setDeploymentFee] = useState({});
  const [cbSelect, setCbSelect] = useState(false);
  const [showToast, setShowToast] = useState(false);

  useEffect(() => {
    if (showToast) {
      setTimeout(() => {
        setShowToast(false);
      }, 4000);
    }
  }, [showToast]);

  const handleClose = () => {
    setShow(false);
    setCbSelect(false);
  };

  const handleShow = async () => {
    try {
      dispatch(setSkeleton(true));
      setShow(true);
      const resp = await ApiCall("GET", `/rest/forge/getFees/${savedFirstStep.id}`);
      setDeploymentFee(resp);
      dispatch(setSkeleton(false));
    } catch (error) {
      console.log(error);
      dispatch(setSkeleton(false));
      toast.error("Something went wrong.");
    }
  };

  const methods = useForm({
    resolver: yupResolver(secondStepValidation),
    defaultValues: {
      groupList: [...selectedAllowlistGroups],
    },
    mode: "all",
  });

  const {
    handleSubmit,
    formState: { errors },
    watch,
    setValue,
    control,
    setError,
    clearErrors,
  } = methods;

  const { fields } = useFieldArray({
    control,
    name: "groupList",
  });

  useFormPersist("pricingForm", {
    watch,
    setValue,
    storage: window.localStorage,
  });

  const groupList = watch("groupList");

  const onSubmit = async (data, isDraft) => {
    try {
      const payload = data.groupList.map((item) => ({
        ...item,
      }));

      payload.map((item) => {
        Object.keys(item).map((key) => {
          if (!item[key]) delete item[key];
        });
      });

      await ApiCall("PUT", `/rest/forge/${isDraft ? "pricing" : "launch"}/${savedFirstStep.id}`, {
        allowListGroup: payload,
      });
      toast.success(`${isDraft ? "Draft saved." : "Launched Successfully."}`);

      document.getElementById("root").scrollTo({ top: 0, behavior: "smooth" });
      if (!isDraft) {
        localStorage.removeItem("pricingForm");
        localStorage.removeItem("basicForm");

        dispatch(setSaveDraftData(null));
        dispatch(setSavedFirstStep(null));
        dispatch(setCurrentFormStep(1));
        navigate("/manage");
        goNext();
      }
    } catch (error) {
      dispatch(setLoader(false));
      console.log(error);
      toast.error(errorMessages[error?.code] || error?.response?.data?.message || "Something went wrong.");
    }
  };

  const getRequiredError = (err) => {
    for (let [_, value] of Object.entries(Array.isArray(err) ? err[0] || {} : {})) {
      dispatch(setLoader(false));
      if (value?.type === "required") {
        setShowToast(true);
        if (!showToast) {
          toast.error("Fields marked by a red dot are required.");
        }
        break;
      } else {
        if (Array.isArray(value)) {
          getRequiredError(value);
        }
      }
    }
  };

  const handleFormSubmit = async (isDraft, signature) => {
    try {
      dispatch(setLoader(true));
      getRequiredError(methods.formState.errors?.groupList);
      if (!isDraft) {
        await makeTransaction(savedFirstStep.id, signature);
        handleClose();
      }
      await handleSubmit(onSubmit)(isDraft);
      dispatch(setLoader(false));
    } catch (error) {
      console.log("MAKE TRANSACTION ERROR: ", error);
      dispatch(setLoader(false));
      toast.error(errorMessages[error?.code] || error?.reason || "Something went wrong.");
    }
  };

  const handleOnChange = (e, index) => {
    const value = e.target.value;
    setValue(`groupList.${index}.maxAllocated`, value, { shouldTouch: true });

    if (!value) return;

    if (!/^[1-9][0-9]*$/.test(Number(value))) {
      setError(`groupList.${index}.maxAllocated`, { type: "custom", message: "Number cannot be zero or negative." });
    } else if (parseFloat(value) > parseInt(savedFirstStep.maxSupply) - parseFloat(savedFirstStep.ownerReserve)) {
      setError(`groupList.${index}.maxAllocated`, {
        type: "custom",
        message: "Max Allocated supply cannot be greater total number of NFT's that can be minted",
      });
    } else {
      clearErrors(`groupList.${index}.maxAllocated`);
    }
  };

  return (
    <>
      <div className="comon-all-body hmepage-1 allowist-p1 new-allow-from float-start w-100">
        <div className="comon-div">
          <FormProvider {...methods}>
            <form
              onSubmit={(e) => {
                e.preventDefault();
                handleShow();
              }}
            >
              <div className="inside-div-cm top-allow-form col-lg-10 w-90 mb-0 mx-auto d-block">
                <h3> Pricing </h3>
                <p>Configure pricing across allowlist groups.</p>
              </div>
              <div className="inside-div-cm col-lg-10 w-90 mx-auto d-block mb-3">
                <div className="clone-main-div">
                  <ul>
                    {fields.map((item, index) => {
                      return (
                        <li key={`groupList.${index}`}>
                          <div className="comon-opcity-div">
                            <h3>{item.groupName}</h3>
                            <h5> {"Configure the pricing for this list."} </h5>
                            <div className="show-main-register-mint">
                              <div className="comon-input-div mt-2">
                                <label>Price</label>
                                <div className="inpy-div d-flex mt-2">
                                  <FormInput
                                    name={`groupList.${index}.price`}
                                    type="text"
                                    className="mb-0 w-100"
                                    inputClassName="w-100"
                                  />
                                  <div className="slp">
                                    <span className="form-select">ETH</span>
                                  </div>
                                </div>
                              </div>
                              <div className="row my-3">
                                <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12 form-group mt-2">
                                  <div
                                    className={`form-group ${
                                      errors?.groupList && errors?.groupList[index]?.maxAllocated?.message
                                        ? "form-error"
                                        : ""
                                    }`}
                                  >
                                    <label>Max Allocated Supply</label>
                                    <input
                                      name={`groupList.${index}.maxAllocated`}
                                      type="text"
                                      className="mb-0 w-100 form-control"
                                      value={groupList[index].maxAllocated}
                                      onChange={(e) => handleOnChange(e, index)}
                                    />
                                    {errors?.groupList &&
                                      errors?.groupList[index]?.maxAllocated &&
                                      errors?.groupList[index]?.maxAllocated?.type !== "required" && (
                                        <div className="error-text mt-1">
                                          <span className="info">i</span>
                                          <span>
                                            {errors?.groupList && errors?.groupList[index]?.maxAllocated.message}
                                          </span>
                                        </div>
                                      )}
                                  </div>
                                </div>
                                <div className="col-lg-6">
                                  <FormInput
                                    name={`groupList.${index}.limitPerWallet`}
                                    type="text"
                                    label={"Limit Per Wallet"}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </div>

              <div className="inside-div-cm col-lg-10 w-90 mx-auto d-block mt-5">
                <div className="d-sm-flex align-items-center justify-content-end">
                  <div className="right-pre-div d-flex align-items-center justify-content-between">
                    <button type="button" onClick={() => handleFormSubmit(true)} className="btn pre-btn">
                      Save Draft
                    </button>

                    <button type="submit" className="btn next-btn" disabled={Object.keys(errors).length > 0}>
                      Deploy
                      <span className="d-flex">
                        <img src={ArrowRight} alt="Icon" />
                      </span>
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </FormProvider>
        </div>
      </div>
      {show && (
        <DeploymentModal
          show={show}
          handleClose={handleClose}
          handleFormSubmit={handleFormSubmit}
          fee={deploymentFee}
          cbSelect={cbSelect}
          setCbSelect={setCbSelect}
        />
      )}
    </>
  );
}

export default PricingGroupsForm;
