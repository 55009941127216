import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  basicConfigForm: {},
  saveDraftData: null,
  savedFirstStep: null,
  loader: false,
  currentFormStep: 1,
  selectedAllowlistGroups: [],
  isEditForgeId: false,
  isNextFetch: {},
  skeleton: false,
  isWalletEdit: false,
};

export const reducer = createSlice({
  name: "basicConfigForm",
  initialState,
  reducers: {
    setBasicConfigForm: (state, { payload }) => {
      state.basicConfigForm = payload;
    },
    setSaveDraftData: (state, { payload }) => {
      state.saveDraftData = payload;
    },
    setSavedFirstStep: (state, { payload }) => {
      state.savedFirstStep = payload;
    },
    setLoader: (state, { payload }) => {
      state.loader = payload;
    },
    setCurrentFormStep: (state, { payload }) => {
      state.currentFormStep = payload;
    },
    setSelectedAllowlistGroups: (state, { payload }) => {
      state.selectedAllowlistGroups = payload;
    },
    setIsEditForgeId: (state, { payload }) => {
      state.isEditForgeId = payload;
    },
    setIsNextFetch: (state, { payload }) => {
      state.isNextFetch = payload;
    },
    setSkeleton: (state, { payload }) => {
      state.skeleton = payload;
    },
    setIsWalletEdit: (state, { payload }) => {
      state.isWalletEdit = payload;
    },
  },
});

export const {
  setBasicConfigForm,
  setSaveDraftData,
  setSavedFirstStep,
  setLoader,
  setCurrentFormStep,
  setSelectedAllowlistGroups,
  setIsEditForgeId,
  setIsNextFetch,
  setSkeleton,
  setIsWalletEdit,
} = reducer.actions;

export default reducer.reducer;
