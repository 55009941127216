import React from "react";
import { NavLink } from "react-router-dom";
import { useMetaMask } from "metamask-react";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";

import { useAuth } from "../../hooks/useAuth";
import ArrowRight from "../../assets/images/arrowRight.svg";
import { signMessage } from "../../utils/signer";
import { setLoader } from "../../store/reducer";

const Connect = () => {
  document.title = "Forge - Connect";

  const { login } = useAuth();
  const dispatch = useDispatch();

  const { status, connect, account, chainId } = useMetaMask();

  const metamaskHandler = () => {
    connect()
      .then((res) => {
        if (account !== null) {
          //check for existing token in local storage
          signMessage(res[0]).then((signature) => {
            if (signature) {
              login(signature);
            } else {
              toast.error("Please authenticate to proceed further.");
            }
          });
        }
      })
      .catch((err) => {
        if (err?.code === 4001) toast.error("Connection failed! Please try again.");
        dispatch(setLoader(false));
      });
  };

  return (
    <div className="container">
      <div className="connect-wallet-wrap">
        <div>
          <NavLink className="navbar-brand" to="/" style={{ display: "block", maxWidth: "667px" }}>
            <img src="images/8080-logo.png" alt="logo" />
          </NavLink>
          <h2 className="mint-text">Mint</h2>
        </div>

        <button onClick={metamaskHandler} className="btn connect-outline-btn">
          {status === "connected" ? "Authenticate" : "Connect Wallet"} <img src={ArrowRight} alt="ArrowRight" />
        </button>
      </div>
    </div>
  );
};
export default Connect;
