import React from "react";
import { Modal } from "react-bootstrap";
import { IoIosClose } from "react-icons/io";
import ArrowRight from "../../../../assets/images/arrowRight.svg";

const MintConfirmationModal = ({ confirmMintModal, confirmMintModalClose, handleConfirmMint, isUpdate }) => {
  return (
    <Modal className="pause delete-confirmation" centered show={confirmMintModal} onHide={confirmMintModalClose}>
      <Modal.Header className="border-0 justify-content-end">
        <button onClick={confirmMintModalClose} className="text-white p-0 btn">
          <IoIosClose size="30px" />
        </button>
      </Modal.Header>
      <Modal.Body>
        <p>
          {`You are about to ${isUpdate ? "change" : "set"} the mint conditions of this group on the smart contract.
          This will initiate a`}{" "}
          <span>transaction</span>.
        </p>
      </Modal.Body>
      <Modal.Footer className="justify-content-start border-0">
        <button
          onClick={() => {
            handleConfirmMint();
            confirmMintModalClose();
          }}
          className="btn next-btn m-0"
        >
          Confirm
          <span className="d-flex">
            <img src={ArrowRight} alt="Icon" />
          </span>
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default MintConfirmationModal;
