import { ethers, BigNumber } from "ethers";
// confirm this - need to make it dynammic based on contract specs - implementation
import ERC721_ABI from "./abi/ForgeERC721.json";
import ERC721A_ABI from "./abi/ForgeERC721A.json";
import paymentAbi from "./abi/paymentAbi.json";
import config from "../utils/config.json";

let contract;
let provider;
let signer;

export const initializeContract = (contractAddress, implementation = "ERC721Enumerable") => {
  try {
    console.log(" CONTRACT INITIALIZED ");
    const abi = implementation === "ERC721Enumerable" ? ERC721_ABI : ERC721A_ABI;
    provider = new ethers.providers.Web3Provider(window.ethereum);
    signer = provider.getSigner();
    contract = new ethers.Contract(contractAddress, abi, signer);
    return contractAddress;
  } catch (err) {
    console.log(err);
  }
};

export const makeTransaction = async (projectId, sign) => {
  console.log("MAKE TRANSACT: ", projectId, sign, config.productName, config.payContractAddress);

  // TODO: Better handelling of contract initialization
  // added here to fix null signer
  initializeContract(config.payContractAddress);

  const payContract = new ethers.Contract(config.payContractAddress, paymentAbi, signer);
  const transaction = await payContract.takePayment(projectId, config.productName, sign, { value: sign[1] });
  return await transaction.wait();
};

export const isPaused = async () => {
  return await contract?.paused();
};

export const isRevealed = async () => {
  return await contract?.reveal();
};

export const togglePause = async () => {
  const transaction = await contract?.togglePause();
  return await transaction?.wait();
};

export const getSaleConditions = async (groupId) => {
  const keccak = BigNumber.from(ethers.utils.keccak256(ethers.utils.toUtf8Bytes(groupId)));
  return await contract?.saleConditions(keccak);
};

export const setSaleConditions = async (saleConditions, groupId) => {
  const keccak = BigNumber.from(ethers.utils.keccak256(ethers.utils.toUtf8Bytes(groupId)));
  const transaction = await contract.setSaleConditions(saleConditions, [keccak]);
  return await transaction?.wait();
};

export const updateSaleCondition = async (saleConditions, groupId) => {
  const keccak = BigNumber.from(ethers.utils.keccak256(ethers.utils.toUtf8Bytes(groupId)));
  const transaction = await contract.updateSaleCondition(saleConditions, keccak);
  return await transaction?.wait();
};
export const withdraw = async () => {
  const transaction = await contract.withdraw();
  return await transaction?.wait();
};

export const setBaseURI = async (postRevealUri, reveal) => {
  const transaction = await contract.setBaseURI(postRevealUri, reveal);
  return await transaction?.wait();
};

export const getOwnerRemainingCap = async () => {
  // owners remaining capacity out of total reserved by the owner
  const res = await contract.ownerMintLeft();
  return res.toNumber();
};

export const getMaxSupply = async () => {
  // collection total size
  const res = await contract.maxSupply();
  return res.toString();
};

export const getAllPayeesAndShares = async () => {
  const res = await contract.getAllPayeesAndShares();
  let data = [];
  for (let i = 0; i < res[0].length; i++) {
    data.push({ walletAddress: res[0][i], percentageSplit: (parseFloat(res[1][i]) / 100).toString() });
  }
  return data;
};

export const isWithdrawn = async () => {
  const res = await contract.withdrawn();
  return res;
};

// write functions
export const setMaxSupply = async (supply) => {
  const transaction = await contract.setMaxSupply(supply);
  return await transaction?.wait();
};

export const setMaxSupplyStaticCall = async (supply) => {
  return await contract.callStatic.setMaxSupply(supply);
};

export const setOwnerCap = async (cap) => {
  const transaction = await contract.setOwnerCap(cap);
  return await transaction?.wait();
};

export const setOwnerCapStaticCall = async (cap) => {
  return await contract.callStatic.setOwnerCap(cap);
};

export const setPayeeAndShares = async (addresses, shares) => {
  const transaction = await contract.setPayeeAndShares(addresses, shares);
  return await transaction?.wait();
};
