/*
 This file is used to show the mints that is either deployed or in draft.
 The API for each forge detail will be call only when we go to next slide and that detail is not being called before.
*/

import React, { createRef, useEffect, useMemo, useState } from "react";
import { ImArrowRight2 } from "react-icons/im";
import { FiChevronRight, FiChevronLeft } from "react-icons/fi";
import Slider from "react-slick";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import Header from "../../components/Header";
import Launched from "./components/Launched";
import Draft from "./components/Draft";
import ArrowRight from "../../assets/images/arrowRight.svg";
import { ApiCall } from "../../utils/ApiUtils";
import {
  setCurrentFormStep,
  setIsNextFetch,
  setLoader,
  setSavedFirstStep,
  setSaveDraftData,
  setSelectedAllowlistGroups,
} from "../../store/reducer";

import {
  initializeContract,
  isRevealed,
  isPaused,
  togglePause,
  withdraw,
  setBaseURI,
  isWithdrawn,
} from "../../web3.js/contractIngration";
import { errorMessages } from "../../utils/errorMessages";

const MINUTE_MS = 30000;

const Manage = () => {
  document.title = "Forge - Manage";

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const customSlider = createRef();

  const isNextFetch = useSelector(({ isNextFetch }) => isNextFetch);

  const [draftData, setDraftData] = useState([]);
  const [deployedData, setDeployedData] = useState([]);
  const [filterData, setFilterData] = useState([]);
  const [sliderIndex, setSliderIndex] = useState(0);
  const [searchTxt, setSearchTxt] = useState("");
  const [pillsTab, setPillsTab] = useState("Launched");
  const [isMintPaused, setIsMintPaused] = useState(false);
  const [contract, setContract] = useState("");
  const [isArtReveled, setIsArtRevealed] = useState(true);
  const [isWithdrawnBefore, setIsWithdrawnBefore] = useState(false);
  const [mintData, setMintData] = useState({});

  const settings = {
    accessibility: false,
    className: "center",
    centerMode: true,
    Infinity: false,
    infinite: false,
    arrows: false,
    centerPadding: "400px",
    slidesToShow: 1,
    draggable: false,
    speed: 500,
    beforeChange: (_, next) => setSliderIndex(next),
    afterChange: (current) => setSliderIndex(current),
    responsive: [
      {
        breakpoint: 1800,
        settings: {
          centerPadding: "300px",
        },
      },
      {
        breakpoint: 1600,
        settings: {
          centerPadding: "200px",
        },
      },
      {
        breakpoint: 1400,
        settings: {
          centerPadding: "150px",
        },
      },
      {
        breakpoint: 1280,
        settings: {
          centerPadding: "100px",
        },
      },
      {
        breakpoint: 1024,
        settings: {
          centerPadding: "50px",
        },
      },
      {
        breakpoint: 767,
        settings: {
          centerPadding: "30px",
        },
      },
    ],
  };

  // This useEffect ensures that the page will always scroll to the top.
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // if the token in local storage doesn't exists then it will be navigated to authentication page.
  useEffect(() => {
    if (!localStorage.getItem("token")) {
      navigate("/");
    } else {
      fetchData();
    }
  }, []);

  // getIsWithdrawn function will provide the returns the boolean value for the current state of withdrawal of a mint.
  useEffect(() => {
    const getIsWithdrawn = async () => {
      const n = await isWithdrawn();
      setIsWithdrawnBefore(n);
    };

    // This should be called only after contract is initialized
    if (!!contract) getIsWithdrawn();
  }, [contract]);

  // getRevealedStatus function will provide the returns the boolean value for the current state of revealed of a mint.
  const getRevealedStatus = async () => {
    const n = await isRevealed();
    setIsArtRevealed(n);
  };

  // This should be called only after contract is initialized
  useEffect(() => {
    if (!!contract) getRevealedStatus();
  }, [contract]);

  /*
    getForgeDetail will call the signle forge API and replace the current mint with the single forge response if the status is different for both.
    The main purpose for this API is to update the mint state from deployment to deployed to show user the newly deployed forge without refreshing the page manually.
    The result of this api call is used to show the minted, owners, and balance of the mint.
  */
  const getForgeDetail = async (forge) => {
    try {
      const result = await ApiCall("GET", `/rest/forge/${forge.id}`);
      if (result.status !== forge.status) {
        const data = deployedData;
        data[sliderIndex] = result;
        setDeployedData(data);
      }
      return result;
    } catch (err) {
      console.log(err);
      toast.error(err?.response?.data?.message || "Failed to get data.");
    }
  };

  /*
    This useEffect will call the getForgeDetail function 
    if the current forge status is payment_pending or in_deployment 
    in every 30 seconds to auto refresh the current forge.
  */
  useEffect(() => {
    const interval = setInterval(() => {
      const currentForge = deployedData[sliderIndex];
      if (currentForge?.status === "payment_pending" || currentForge?.status === "in_deployment") {
        getForgeDetail(currentForge);
      }
    }, MINUTE_MS);

    return () => clearInterval(interval);
  }, []);

  /*
    fetchData function will get all the forge and then store that in deployment or draft state.
    The data will be stored in ascending order by created at time.
  */
  const fetchData = async () => {
    try {
      dispatch(setLoader(true));
      const result = await ApiCall("GET", `/rest/forge`);
      const deployedDataRes = result
        ?.filter(
          (item) => item.status === "deployed" || item.status === "payment_pending" || item.status === "in_deployment"
        )
        .reverse((a, b) => {
          return new Date(b.createdAt) - new Date(a.createdAt);
        });
      setDeployedData(deployedDataRes);
      setDraftData(result?.filter((item) => item.status === "draft"));
      dispatch(setLoader(false));

      // The first forge is already active so this is used to call the more detail of the forge.
      dispatch(
        setIsNextFetch({
          ...isNextFetch,
          [deployedDataRes[0]?.id]: true,
        })
      );

      dispatch(setLoader(false));
    } catch (error) {
      dispatch(setLoader(false));
      toast.error("Failed to load. Please try again later.");
      console.log(error);
    }
  };

  /*
    getSingleForgeDetail will call the single forge api and update the data for minted, owner and balance.
    The reason to place is here is we have to withdrawal value in real time after user is done with withdrawal
  */
  const getSingleForgeDetail = async (dataItem) => {
    const { Minted, Owners, Balance } = await getForgeDetail(dataItem);
    setMintData({ Minted, Owners, Balance });
  };

  // This will filter the data based on search texts.
  useEffect(() => {
    const filteredData = deployedData.filter((item) => {
      return (item?.name).toLocaleLowerCase().search(searchTxt.toLocaleLowerCase()) >= 0;
    });
    setFilterData(filteredData);

    if (filteredData.length !== deployedData.length) {
      customSlider?.current?.slickGoTo(0);
    }
  }, [deployedData, searchTxt]);

  useMemo(() => {
    if (filterData[sliderIndex]?.status === "deployed") {
      const contractAddress = filterData[sliderIndex]?.deployment?.Address;
      const implementation = filterData[sliderIndex].implementation;
      const initialize = async () => {
        const n = await initializeContract(contractAddress, implementation);
        setContract(n);
      };
      if (contract !== contractAddress) initialize();
    }
  }, [sliderIndex, filterData]);

  useEffect(() => {
    if (filterData?.length === 0) {
      setSliderIndex(0);
    }
  }, [filterData, setSliderIndex]);

  const gotoNext = () => {
    setIsArtRevealed(true);
    customSlider.current.slickNext();
    dispatch(
      setIsNextFetch({
        ...isNextFetch,
        [filterData[sliderIndex + 1]?.id]: true,
      })
    );
  };

  const gotoPrev = () => {
    setIsArtRevealed(true);
    customSlider.current.slickPrev();
  };

  const handlePause = async (id) => {
    try {
      dispatch(setLoader(true));

      // web 3 pause mint
      const res = await togglePause();
      if (!!res) {
        await ApiCall("PUT", `/rest/forge/toggleMinting/${id}/0`);
        toast.success(`Mint has been paused.`);
        setIsMintPaused(!isMintPaused);
      } else {
        toast.error("Failed to pause. Please try again later.");
      }
      dispatch(setLoader(false));
    } catch (error) {
      dispatch(setLoader(false));
      toast.error(errorMessages[error?.code] || error.reason || "Failed to pause. Please try again later.");
    }
  };

  const handleResume = async (id) => {
    try {
      dispatch(setLoader(true));
      const res = await togglePause();
      if (!!res) {
        await ApiCall("PUT", `/rest/forge/toggleMinting/${id}/1`);
        fetchData();
        dispatch(setLoader(false));
        toast.success(`Mint has been resumed.`);
      } else {
        toast.error("Failed to pause. Please try again later.");
      }
    } catch (error) {
      console.log(error);
      dispatch(setLoader(false));
      toast.error(errorMessages[error?.code] || error.reason || "Faled to resume. Please try again later.");
    }
  };

  useEffect(() => {
    // call only if mint is deployed.
    const checkIsMintPaused = async () => {
      try {
        const res = await isPaused();
        setIsMintPaused(res);
      } catch (error) {
        console.log("ISPAUSED ERROR: ", error);
      }
    };
    if (!!contract) checkIsMintPaused();
  }, [contract, handlePause, handleResume]);

  const handleRevealArt = async (postRevealUri) => {
    try {
      dispatch(setLoader(true));
      await setBaseURI(postRevealUri, isArtReveled);
      getRevealedStatus();
      dispatch(setLoader(false));
      toast.success("Art Revealed.");
    } catch (error) {
      console.log(error);
      dispatch(setLoader(false));
      toast.error(errorMessages[error?.code] || error.reason || "Failed to reveal art. Please try again later.");
    }
  };

  const handleWithdraw = async (dateItem) => {
    try {
      dispatch(setLoader(true));
      const res = await withdraw();
      getSingleForgeDetail(dateItem);
      fetchData();
      console.log(res);
      dispatch(setLoader(false));
      toast.success("Withdrawn.");
    } catch (error) {
      console.log(error);
      dispatch(setLoader(false));
      toast.error(errorMessages[error?.code] || error.reason || "Failed to withdraw. Please try again later.");
    }
  };

  const gotoBasicForm = () => {
    localStorage.removeItem("pricingForm");
    localStorage.removeItem("basicForm");

    dispatch(setSaveDraftData(null));
    dispatch(setSavedFirstStep(null));
    dispatch(setCurrentFormStep(1));
    dispatch(setSelectedAllowlistGroups([]));
    navigate("/launch");
  };

  return (
    <>
      <Header />
      <div className="comon-all-body hmepage-1 float-start w-100 mt-3">
        <div className="comon-div">
          <div className="container">
            <h1 className="main-haeding text-center text-white"> Manage Mints </h1>
            <p className="sub-haeding d-block mx-auto text-center text-white fs-22 futura-light">
              Manage launched and draft mints. Edit allowlist groups, initiate withdrawal, reveal art and more.
            </p>
          </div>
          <div className="my-md-5 my-4 mints-carousel">
            <div className="tabs-section">
              <div className="tab-content mb-md-5 mb-4" id="pills-tabContent">
                <div
                  className={`tab-pane fade ${pillsTab === "Launched" ? "active show" : ""}`}
                  id="pills-launched"
                  role="tabpanel"
                >
                  {(deployedData?.length === 0 || deployedData?.length === undefined) && pillsTab === "Launched" ? (
                    <>
                      <div className="container px-0">
                        <div className="mints-right-part draft-wrapper">
                          <div className="d-flex align-items-center gap-2 justify-content-between">
                            <TabMenu pillsTab={pillsTab} setPillsTab={setPillsTab} />
                          </div>
                        </div>
                      </div>
                      <div className="container">
                        <div className="manage-gen-mints-tab launch-sec d-block text-center">
                          <p className="mb-4">Go ahead and launch your first mint</p>
                          <button
                            onClick={gotoBasicForm}
                            className="btn next-btn mx-auto"
                            style={{ minWidth: "200px" }}
                          >
                            <span>Launch</span>
                            <span className="d-flex">
                              <img src={ArrowRight} alt="Icon" />
                            </span>
                          </button>
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="container px-0">
                        <div className="mints-right-part draft-wrapper">
                          <div className="d-flex align-items-center gap-2 justify-content-between">
                            <TabMenu pillsTab={pillsTab} setPillsTab={setPillsTab} />
                            <ul className="d-flex align-items-center gap-2" style={{ height: 28 }}>
                              {sliderIndex !== 0 && filterData?.length !== 0 && (
                                <>
                                  <li className="slick-arrow-btn">
                                    <button
                                      onClick={() => {
                                        gotoPrev();
                                      }}
                                    >
                                      <FiChevronLeft />
                                    </button>
                                  </li>
                                </>
                              )}
                              <li className="search-mint">
                                <input
                                  type="text"
                                  value={searchTxt}
                                  onChange={(e) => setSearchTxt(e.target.value)}
                                  placeholder="Search Mint"
                                />
                              </li>
                              {filterData?.length - `${filterData?.length === 0 ? 0 : 1}` !== sliderIndex &&
                                filterData?.length !== 0 && (
                                  <>
                                    <li className="slick-arrow-btn">
                                      <button
                                        onClick={() => {
                                          gotoNext();
                                        }}
                                      >
                                        <FiChevronRight />
                                      </button>
                                    </li>
                                  </>
                                )}
                            </ul>
                          </div>
                        </div>
                      </div>
                      <Slider {...settings} ref={customSlider}>
                        {filterData?.length !== 0 ? (
                          filterData?.map((item, index) => (
                            <div key={index} className="px-2">
                              <div className="manage-gen-mints-tab">
                                <Launched
                                  dataItem={item}
                                  handlePause={handlePause}
                                  handleResume={handleResume}
                                  fetchData={fetchData}
                                  handleRevealArt={handleRevealArt}
                                  handleWithdraw={handleWithdraw}
                                  getSingleForgeDetail={getSingleForgeDetail}
                                  mintData={mintData}
                                  isMintPaused={isMintPaused}
                                  isArtReveled={isArtReveled}
                                  isSearchFetch={searchTxt ? index === 0 : false}
                                  contract={contract}
                                  activeForgeId={filterData[sliderIndex]?.id}
                                  isWithdrawnBefore={isWithdrawnBefore}
                                />
                              </div>
                              <div className="manage-gen-mints-tab launch-sec">
                                <p>Looking to launch another Mint?</p>
                                <button onClick={gotoBasicForm} className="btn next-btn m-0">
                                  <span>Launch Now</span>
                                  <span className="d-flex">
                                    <img src={ArrowRight} alt="Icon" />
                                  </span>
                                </button>
                              </div>
                            </div>
                          ))
                        ) : (
                          <div className="px-2">
                            <div className="manage-gen-mints-tab launch-sec">
                              <h1 className="mb-0">
                                <i>No data found.</i>
                              </h1>
                            </div>
                          </div>
                        )}
                      </Slider>
                    </>
                  )}
                </div>
                <div
                  className={`tab-pane fade ${pillsTab === "Draft" ? "active show" : ""}`}
                  id="pills-drafts"
                  role="tabpanel"
                >
                  <>
                    <div className="container px-0">
                      <div className="mints-right-part draft-wrapper">
                        <div className="d-flex align-items-center gap-2 justify-content-between">
                          <TabMenu pillsTab={pillsTab} setPillsTab={setPillsTab} />
                        </div>
                      </div>
                    </div>
                    <div className="container">
                      {draftData?.length === 0 ? (
                        <div className="manage-gen-mints-tab launch-sec d-block text-center">
                          <p className="mb-4">No drafts so far</p>
                          <button
                            onClick={gotoBasicForm}
                            className="btn next-btn mx-auto"
                            style={{ minWidth: "200px" }}
                          >
                            <span>Launch</span>
                            <span className="d-flex">
                              <img src={ArrowRight} alt="Icon" />
                            </span>
                          </button>
                        </div>
                      ) : (
                        <Draft draftData={draftData} fetchData={fetchData} />
                      )}
                    </div>
                  </>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const TabMenu = ({ pillsTab, setPillsTab }) => (
  <ul className="nav nav-pills align-items-center mx-0" id="pills-tab" role="tablist">
    <li className="nav-item" role="presentation">
      <button
        onClick={() => setPillsTab("Launched")}
        className={`nav-link ${pillsTab === "Launched" ? "active" : ""}`}
        id="pills-home-tab"
        data-bs-toggle="pill"
        data-bs-target="#pills-launched"
        type="button"
        role="tab"
      >
        Launched
      </button>
    </li>
    <li className="nav-item" role="presentation">
      <button
        onClick={() => setPillsTab("Draft")}
        className={`nav-link  ${pillsTab === "Draft" ? "active" : ""}`}
        id="pills-profile-tab"
        data-bs-toggle="pill"
        data-bs-target="#pills-drafts"
        type="button"
        role="tab"
      >
        Drafts
      </button>
    </li>
  </ul>
);

export default Manage;
