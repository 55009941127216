import { createContext, useContext, useEffect, useMemo } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { setLoader } from "../store/reducer";
import { ApiAuth, ApiCall } from "../utils/ApiUtils";
import { useLocalStorage } from "./useLocalStorage";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useLocalStorage("user", null);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const tokenId = searchParams.get("token");
  const dispatch = useDispatch();

  useEffect(() => {
    if (tokenId) {
      localStorage.setItem("token", tokenId);
    }
  }, [tokenId]);

  const login = async (data) => {
    dispatch(setLoader(true));
    const resp = await ApiAuth(data);
    if (resp || tokenId) {
      setUser(resp);
      localStorage.setItem("token", resp.token);
      const result = await ApiCall("GET", `/rest/forge`);
      if (result?.length > 0) {
        navigate("/manage");
      } else {
        navigate("/launch");
      }
    }
    dispatch(setLoader(false));
  };

  const logout = () => {
    setUser(null);
    localStorage.clear();
    window.location.replace("/");
  };

  const value = useMemo(
    () => ({
      user,
      login,
      logout,
    }),
    [user]
  );

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
  return useContext(AuthContext);
};
