import React from "react";
import { useNavigate } from "react-router-dom";

import Oops from "../../assets/images/oops.svg";

const NotFond = () => {
  const navigate = useNavigate();

  return (
    <div className="container">
      <div className="not-found">
        <div className="opps-text">
          <h1>Error 404!</h1>
          <p>Page not found!</p>
          <button onClick={() => navigate(-1)}>Go back</button>
        </div>
        <div className="opps-icon">
          <img src={Oops} alt="Oops" />
        </div>
      </div>
    </div>
  );
};

export default NotFond;
