export function numberWithCommas(x) {
  return x.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
}

export const convertToSubString = (str, to) => {
  const newString = str?.substring(0, 20) + "..." + str?.substring(str.length - 10, str.length);
  return str.length > 25 ? newString : str;
};

export const cutAddress = (account) => {
  return account?.substring(0, 5) + "...." + account?.substring(account.length - 4);
};
