import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { BiUpload } from "react-icons/bi";
import { toast } from "react-toastify";
import ArrowRight from "../../../../assets/images/arrowRight.svg";
import { AllowlistApiCall } from "../../../../utils/ApiUtils";

const BulkUpload = ({
  bulkUpload,
  bulkUploadModalClose,
  groupId,
  allowlistId,
  setDoHaveAddresses,
  fetchData,
  getNoOfReg,
  onSuccess,
  isGroupQnA,
}) => {
  const [csvfile, setCsvfile] = useState(undefined);
  const [showErr, setShowErr] = useState(false);

  const handleChange = (e) => {
    setShowErr(false);
    console.log("e.target.files[0].type", e.target.files[0].type);
    if (e.target.files[0].type === "text/csv") {
      setCsvfile(e.target.files[0]);
    } else {
      setCsvfile(undefined);
      setShowErr(true);
      toast.error("Please upload a csv file.");
    }
  };

  const importCSV = async () => {
    if (!showErr) {
      try {
        const formData = new FormData();
        formData.append("file", csvfile);

        let response;
        if (isGroupQnA) {
          response = await AllowlistApiCall(
            "POST",
            `/rest/allowlist/${allowlistId}/${groupId}/QnA/addWinners/bulk`,
            formData
          );
        } else {
          response = await AllowlistApiCall("POST", `/rest/allowlist/${allowlistId}/${groupId}/csvUpload`, formData);
        }

        getNoOfReg();
        fetchData();
        setDoHaveAddresses(true);
        onSuccess();
        if (!response) {
          toast.error("Failed to load. Please try again later.");
        }
        bulkUploadModalClose();
        setCsvfile();
      } catch (error) {
        toast.error("Something went wrong.");
      }
    }
  };

  return (
    <Modal className="edit-modal bulk-upload" size="lg" centered show={bulkUpload} onHide={bulkUploadModalClose}>
      <Modal.Header closeButton className="border-0">
        <h4>Bulk Upload</h4>
      </Modal.Header>
      <Modal.Body>
        <div className={`form-group ${showErr ? "form-error dot" : ""}`}>
          <div className="box">
            <section className="outputname" style={{ color: "#5A5A5A" }}>
              {csvfile ? csvfile?.name : "Upload Allowlist (.csv)"}
            </section>
            <input
              type="file"
              accept=".csv"
              onChange={(e) => {
                handleChange(e);
              }}
              className="inputfile inputfile-1"
            />
            <BiUpload />
          </div>
          {showErr && (
            <div className="error-text mt-1">
              <span className="info">i</span>
              <span>Please choose a CSV file</span>
            </div>
          )}
        </div>
      </Modal.Body>
      <Modal.Footer className="justify-content-end border-0">
        <button onClick={importCSV} className="btn next-btn m-0">
          Done
          <span className="d-flex">
            <img src={ArrowRight} alt="Icon" />
          </span>
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default BulkUpload;
