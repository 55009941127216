import { Checkbox } from "pretty-checkbox-react";
import { Modal } from "react-bootstrap";
import Skeleton from "react-loading-skeleton";
import { useSelector } from "react-redux";
import ArrowRight from "../../../assets/images/arrowRight.svg";

const DeploymentModal = ({ show, handleClose, handleFormSubmit, fee, cbSelect, setCbSelect }) => {
  const { deploymentFees, gasFees, totalFees, paymentSignature } = fee;
  const skeleton = useSelector(({ skeleton }) => skeleton);

  return (
    <Modal className="delete-confirmation" centered show={show} onHide={handleClose}>
      <Modal.Header closeButton className="border-0">
        <Modal.Title>Confirm Deployment</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="fee-block">
          <div className="row">
            <div className="col-12 d-flex justify-content-between">
              <span> Deployment Fee </span>
              <span className="fee-block-purple">
                {skeleton ? <Skeleton width={180} /> : `${parseFloat(deploymentFees?.fees).toFixed(4)} ETH`}
              </span>
            </div>
          </div>
          <div className="row text-end">
            <p> {skeleton ? <Skeleton width={80} /> : `$${parseFloat(deploymentFees?.ethPrice).toFixed(2)}`} </p>
          </div>
          <div className="row mt-3">
            <div className="col-12 d-flex justify-content-between">
              <span> Gas Fee </span>
              <span className="fee-block-purple">
                {skeleton ? <Skeleton width={220} /> : `${parseFloat(gasFees?.fees).toFixed(4)} ETH`}
              </span>
            </div>
          </div>
          <div className="row text-end">
            <p> {skeleton ? <Skeleton width={150} /> : `$${parseFloat(gasFees?.ethPrice).toFixed(2)}`} </p>
          </div>
          <div className="row mt-3">
            <div className="col-12 d-flex justify-content-between totals-div">
              <span> Total </span>
              <span className="fee-block-purple">
                {skeleton ? <Skeleton width={250} /> : `∼ ${parseFloat(totalFees?.fees).toFixed(4)} ETH`}
              </span>
            </div>
          </div>
          <div className="row text-end total-amounts-div">
            <p>{skeleton ? <Skeleton width={180} /> : `$${parseFloat(totalFees?.ethPrice).toFixed(2)}`}</p>
          </div>
        </div>

        <div className="checkbox-block">
          <Checkbox
            icon={<i className="mdi mdi-check" />}
            checked={cbSelect}
            onChange={(e) => setCbSelect(e.target.checked)}
          />
          <p className="ms-2">
            By making payment, you agree to the{" "}
            <a href="https://8080.build/terms" target={"_blank"}>
              {" "}
              terms and conditions{" "}
            </a>{" "}
            of our platform.
          </p>
        </div>
      </Modal.Body>
      <Modal.Footer className="justify-content-start border-0">
        <span
          onClick={() => {
            handleClose();
          }}
          className="cursor-pointer text-light mr-2"
        >
          Cancel
        </span>
        <button
          disabled={!cbSelect || skeleton}
          onClick={() => {
            handleFormSubmit(false, paymentSignature);
          }}
          className="btn next-btn m-0 ms-4"
        >
          Confirm
          <span className="d-flex">
            <img src={ArrowRight} alt="Icon" />
          </span>
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default DeploymentModal;
