import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { setLoader } from "../../../store/reducer";
import { ApiCall } from "../../../utils/ApiUtils";
import EditMintPrice from "./Modal/EditMintPrice";
import AddAddress from "./Modal/AddAddress";
import { getSaleConditions, setSaleConditions } from "../../../web3.js/contractIngration";
import MintConfirmationModal from "./Modal/MintConfirmationModal";
import { ethers } from "ethers";
import { errorMessages } from "../../../utils/errorMessages";

const LaunchedItem = (props) => {
  const { fetchData, groupItem, dataItem, getNoOfReg, index, isDeploymentInProg, contract, isMintPaused, registrant } =
    props;

  const dispatch = useDispatch();

  const [editModal, setEditModal] = useState(false);
  const [addressModal, setAddressModal] = useState(false);
  const [doHaveAddresses, setDoHaveAddresses] = useState(false);
  const [address, setAddress] = useState([]);
  const [confirmMintModal, setConfirmMintModal] = useState(false);
  const [percentageMinted, setPercentageMinted] = useState(0);
  const [mintingMode, setMintingMode] = useState(false);

  const editModalClose = () => setEditModal(false);
  const editModalShow = () => setEditModal(true);

  const confirmMintModalClose = () => setConfirmMintModal(false);
  const confirmMintModalShow = () => setConfirmMintModal(true);

  useEffect(() => {
    const asyncFunc = async () => {
      try {
        const saleDetail = await getSaleConditions(groupItem?.id);
        if (saleDetail) {
          const tokenMinted = saleDetail["totalTokenMinted"]?.toNumber();

          const n = (tokenMinted / groupItem.maxAllocated) * 100;
          setPercentageMinted(isNaN(n) ? 0 : n);
        }
      } catch (error) {
        console.log(error);
      }
    };
    if (!!contract) asyncFunc();
  }, [groupItem, contract]);

  const addressModalShow = async () => {
    if (registrant?.winners?.length > 0) {
      setDoHaveAddresses(true);
    }
    setAddressModal(true);
  };

  const addressModalClose = () => {
    setAddressModal(false);
    setDoHaveAddresses(false);
    setAddress([]);
  };

  const handleConfirmMint = async () => {
    try {
      dispatch(setLoader(true));
      const sale = await getSaleConditions(groupItem.id);
      if (sale && !sale["groupID"]) {
        await setSaleConditions(
          [
            [
              true,
              groupItem.limitPerWallet,
              groupItem.maxAllocated,
              0, // total token minted
              ethers.utils.parseEther(groupItem.price?.toString()),
              groupItem.id,
              "0x0000000000000000000000000000000000000000",
            ],
          ],
          groupItem.id
        );
      }
      await ApiCall("PUT", "/rest/forge/pricing/" + dataItem.id, {
        groupId: groupItem.id,
        allowListGroup: [{ ...groupItem, isMinting: true, mintingInitialize: true, isSaleCreated: true }],
      });
      fetchData();
      dispatch(setLoader(false));
      toast.success(registrant?.groupName + " is minting.");
    } catch (error) {
      console.log(error);
      dispatch(setLoader(false));
      toast.error(
        errorMessages[error?.code] ||
          error?.reason ||
          error?.errror?.message ||
          error?.response?.data?.message ||
          "Something went wrong."
      );
    }
  };

  const handleMinting = async () => {
    try {
      dispatch(setLoader(true));
      const sale = await getSaleConditions(groupItem.id);
      if (sale && sale["groupID"]) {
        await ApiCall(
          "PUT",
          `/rest/forge/toggleGroupMinting/${dataItem.id}/${groupItem.id}/${groupItem.isMinting ? 0 : 1}`
        );
        toast.success(
          groupItem.isMinting ? registrant?.groupName + " is not minting." : registrant?.groupName + " is minting."
        );
        fetchData();
      } else {
        if (groupItem.price >= 0 && groupItem.limitPerWallet > 0 && groupItem.maxAllocated > 0) {
          confirmMintModalShow();
        } else {
          setMintingMode(true);
          editModalShow();
        }
      }
      dispatch(setLoader(false));
    } catch (error) {
      dispatch(setLoader(false));
      toast.error(error?.response?.data?.message || "Something went wrong.");
    }
  };

  return (
    <li className="position-relative" style={{ zIndex: `${99 - index}` }}>
      {isDeploymentInProg ? (
        <div className="group-content-box">
          <div className="min-height-80" />
        </div>
      ) : (
        <>
          <div className="group-content" style={{ marginBottom: "1.3rem" }}>
            <div className="left-content">
              <div className="d-flex align-items-center gap-3">
                <h5 className="mb-0">{registrant?.groupName}</h5>
              </div>

              <div className="d-flex mt-md-3 mt-1 gap-2">
                <button className="btn-gradient btn-gradient-after" onClick={editModalShow}>
                  Edit
                </button>
                <button className="btn-gradient btn-gradient-after" onClick={addressModalShow}>
                  Manage Addresses
                </button>
              </div>
            </div>
            <div
              className={`d-flex align-items-end ${groupItem?.isMinting ? "show-main-up-mint" : ""}`}
              style={{ opacity: isMintPaused ? 0.5 : 1 }}
            >
              <button onClick={handleMinting} className="btn tog-btn p-0 border-0" disabled={isMintPaused}>
                <div className="d-flex align-items-center active-in-toggle-btn">
                  <span className="pb fs-24 futura-light"> Minting </span>
                  <div className="toggle-bn"></div>
                  <span className="pr fs-24 hide-z-index futura-light"> Not Minting </span>
                </div>
              </button>
            </div>
          </div>
          <div className="group-content-box">
            <div className="row w-100 m-0 align-items-center">
              <div className="col-3">
                <h1 className="mb-0">{registrant?.winnersCount || 0}</h1>
                <p className="mb-0">Eligible Minters</p>
              </div>
              <div className="col-3">
                <h1 className="mb-0">
                  {`${percentageMinted % 1 === 0 ? percentageMinted : percentageMinted.toFixed(2)}%`}
                </h1>
                <p className="mb-0">Percentage Minted</p>
              </div>
              <div className="col-3">
                <h1 className="mb-0">{groupItem?.price || 0}</h1>
                <p className="mb-0">Mint Price(ETH)</p>
              </div>
              <div className="col-3">
                <h1 className="mb-0">{groupItem?.limitPerWallet || 0}</h1>
                <p className="mb-0">Limit Per Wallet</p>
              </div>
            </div>
          </div>
        </>
      )}
      {addressModal && (
        <AddAddress
          addressModal={addressModal}
          addressModalClose={addressModalClose}
          groupItem={groupItem}
          item={dataItem}
          setDoHaveAddresses={setDoHaveAddresses}
          doHaveAddresses={doHaveAddresses}
          address={address}
          registrant={registrant}
          setAddress={setAddress}
          getNoOfReg={getNoOfReg}
          setAddressModal={setAddressModal}
          fetchData={fetchData}
          onSuccess={() => toast.success("List updated!")}
        />
      )}
      {editModal && (
        <EditMintPrice
          editModal={editModal}
          dataItem={dataItem}
          editModalClose={editModalClose}
          groupName={registrant.groupName}
          groupItem={groupItem}
          mintingMode={mintingMode}
          confirmMintModal={confirmMintModal}
          confirmMintModalShow={confirmMintModalShow}
          confirmMintModalClose={confirmMintModalClose}
          handleConfirmMint={handleConfirmMint}
          fetchData={fetchData}
          setMiningMode={setMintingMode}
        />
      )}
      {confirmMintModal && (
        <MintConfirmationModal
          confirmMintModal={confirmMintModal}
          confirmMintModalClose={confirmMintModalClose}
          handleConfirmMint={handleConfirmMint}
        />
      )}
    </li>
  );
};

export default LaunchedItem;
