import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { useMetaMask } from "metamask-react";
import { useNavigate, useLocation } from "react-router-dom";
import { BsXCircleFill, BsArrowRight, BsFillCircleFill } from "react-icons/bs";
import { cutAddress } from "../utils/common";
import { Modal } from "react-bootstrap";
import { useDispatch } from "react-redux";

import ArrowRight from "../assets/images/arrowRight.svg";
import { setCurrentFormStep } from "../store/reducer";

function Header() {
  const { account } = useMetaMask();
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const goToHome = () => {
    if (location.pathname.includes("/launch")) {
      handleShow();
    } else {
      navigate("/manage");
    }
  };

  return (
    <>
      <nav id="custom-navbar" className="custom-navbar navbar navbar-expand-lg navbar-light float-start w-100">
        <div className="container px-4">
          <div className="navbar-brand" style={{ cursor: "pointer" }} onClick={goToHome}>
            <img src="images/forge-logo.png" alt="logo" />
          </div>

          <button type="button" className="btn active-id-btn">
            <span className="icon-n3">
              <BsFillCircleFill />
            </span>
            {cutAddress(account, 5)}
          </button>
        </div>
      </nav>
      <div
        className="offcanvas offcanvas-start mobile-menu-div"
        tabIndex="-1"
        id="mobile-menu"
        aria-labelledby="offcanvasExampleLabel"
      >
        <div className="offcanvas-header">
          <button type="button" className="close-menu btn" data-bs-dismiss="offcanvas" aria-label="Close">
            <BsXCircleFill />
          </button>
        </div>
        <div className="offcanvas-body">
          <div className="head-contact">
            <a href="index.html" className="logo-side">
              <img src="images/8080.png" alt="logo" />
            </a>
            <div className="mobile-menu-sec mt-3">
              <ul className="list-unstyled">
                <li data-bs-dismiss="offcanvas">
                  <NavLink to="/" className="nav-link">
                    Mint
                  </NavLink>
                </li>
                <li data-bs-dismiss="offcanvas">
                  <NavLink to="/" className="nav-link">
                    Stake
                  </NavLink>
                </li>
                <li data-bs-dismiss="offcanvas">
                  <NavLink to="/" className="nav-link">
                    Marketplace
                  </NavLink>
                </li>
                <li data-bs-dismiss="offcanvas">
                  <NavLink to="/" className="btn connect-bnt">
                    Launched Mint <BsArrowRight />
                  </NavLink>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <Modal className="delete-confirmation" centered show={show} onHide={handleClose}>
        <Modal.Header closeButton className="border-0 pb-0"></Modal.Header>
        <Modal.Body>
          <p style={{ maxWidth: "280px" }}>
            Are you sure you want to <span>leave</span> this page? All the unsaved changes will be lost.
          </p>
        </Modal.Body>
        <Modal.Footer className="justify-content-start border-0">
          <button
            onClick={() => {
              navigate("/manage");
              handleClose();
              dispatch(setCurrentFormStep(1));
            }}
            className="btn next-btn m-0"
          >
            Leave
            <span className="d-flex">
              <img src={ArrowRight} alt="Icon" />
            </span>
          </button>
          <button onClick={() => handleClose()} className="btn text-white">
            Cancel
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
export default Header;
