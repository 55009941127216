import React, { useState } from "react";
import WithdrawConfirmation from "./Modal/WithdrawConfirmation";

const MintItem = ({ dataItem, handleWithdraw, isWithdrawnBefore, editProjectDetail, mintData }) => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const editConfirm = async () => {
    editProjectDetail(dataItem.id, true);
  };

  return (
    <>
      <li className="position-relative">
        <div className="group-content-box">
          <div className="row w-100 m-0 align-items-center">
            <div className="col-4">
              <h1 className="mb-0">{mintData.Minted || 0}</h1>
              <p className="mb-0">Minted</p>
            </div>
            <div className="col-4">
              <h1 className="mb-0">{mintData.Owners || 0}</h1>
              <p className="mb-0">Owners</p>
            </div>
            <div className="col-4">
              <h1 className="mb-0">
                {mintData.Balance > 0 ? parseFloat(mintData.Balance).toFixed(4) : mintData.Balance || 0}
              </h1>
              <p className="mb-0 contract-balance">
                Balance(ETH)
                <button
                  className={`btn-gradient btn-gradient-after ${mintData.Balance == 0 && "disabled"}`}
                  disabled={mintData.Balance == 0}
                  onClick={handleShow}
                >
                  Withdraw
                </button>
              </p>
            </div>
          </div>
        </div>
      </li>

      {show && (
        <WithdrawConfirmation
          dataItem={dataItem}
          show={show}
          handleClose={handleClose}
          handleWithdraw={handleWithdraw}
          isWithdrawnBefore={isWithdrawnBefore}
          editConfirm={editConfirm}
        />
      )}
    </>
  );
};

export default MintItem;
