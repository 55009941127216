import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import moment from "moment";

import LaunchedItem from "./LaunchedItem";
import PauseConfirmation from "./Modal/PauseConfirmation";
import MintItem from "./MintItem";
import RevealArtModal from "./Modal/RevealArtModal";
import { AllowlistApiCall } from "../../../utils/ApiUtils";
import { setCurrentFormStep, setIsEditForgeId, setIsWalletEdit } from "../../../store/reducer";
import { ReactComponent as RedirectIcon } from "../../../assets/images/redirect-icon.svg";
import { ConfirmationDialogProvider } from "../../../components/ConfirmationModel";

const Launched = ({
  dataItem,
  handlePause,
  handleResume,
  fetchData,
  handleRevealArt,
  handleWithdraw,
  isMintPaused,
  isArtReveled,
  isSearchFetch,
  contract,
  activeForgeId,
  isWithdrawnBefore,
  getSingleForgeDetail,
  mintData,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const isNextFetch = useSelector(({ isNextFetch }) => isNextFetch);

  const isDeploymentInProg = dataItem.status === "payment_pending" || dataItem.status === "in_deployment";

  const [show, setShow] = useState(false);
  const [revealArtModal, setRevealArtModal] = useState(false);
  const [noOfReg, setNoOfReg] = useState([]);
  const [isFetchedGetNoReg, setIsFetchedGetNoReg] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleRevealArtClose = () => setRevealArtModal(false);
  const handleRevealArtShow = () => setRevealArtModal(true);

  const getNoOfReg = () => {
    AllowlistApiCall("GET", `/rest/allowlist/${dataItem.allowlistId}/getRegistrantsCount`)
      .then((result) => {
        setNoOfReg(result.data);
        setIsFetchedGetNoReg(true);
      })
      .catch((error) => {
        console.log(error);
        toast.error(error?.response?.data?.message || "Failed to get data.");
      });
  };
  useEffect(() => {
    if (activeForgeId === dataItem.id) {
      getSingleForgeDetail(dataItem);
    }
  }, [contract]);

  useEffect(() => {
    if (isSearchFetch || activeForgeId === dataItem.id) {
      getNoOfReg();
    }
  }, [isSearchFetch, activeForgeId]);

  useEffect(() => {
    if (isNextFetch[dataItem.id] && !isFetchedGetNoReg) {
      getNoOfReg();
    }
  }, [isNextFetch]);

  const editProjectDetail = (id, walletEdit) => {
    localStorage.removeItem("basicForm");
    dispatch(setIsEditForgeId(id));
    dispatch(setCurrentFormStep(1));
    dispatch(setIsWalletEdit(walletEdit));
    navigate(`/launch`);
  };

  return (
    <ConfirmationDialogProvider>
      <div className="mint-content-wrap">
        {isDeploymentInProg ? (
          <div className="title-wrap mb-4">
            <div>
              <div className="d-flex align-items-center gap-3">
                <h1 className="mb-0">{dataItem.name}</h1>
              </div>
            </div>
          </div>
        ) : (
          <div className="title-wrap mb-4">
            <div>
              <div className="d-flex align-items-center gap-3">
                <h1 className="mb-0" style={{ opacity: isMintPaused ? 0.5 : 1 }}>
                  {dataItem.name}
                </h1>
                {isMintPaused ? (
                  <span style={{ opacity: 0.5 }}>Paused</span>
                ) : (
                  <a
                    href={`${window.location.protocol}//${dataItem?.urlSlug}.forge-staging.online`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <RedirectIcon height={26} width={26} />
                  </a>
                )}
              </div>
              <p className="launched-time" style={{ opacity: isMintPaused ? 0.5 : 1 }}>
                {`Launched on ${moment(dataItem?.deployment?.date).format("DD MMM, YYYY")}`}
              </p>
            </div>

            <ul>
              <li>
                {!isArtReveled && (
                  <button className="btn-gradient btn-gradient-after" onClick={handleRevealArtShow}>
                    Reveal Art
                  </button>
                )}
              </li>
              <li>
                <button
                  className="btn-gradient btn-gradient-after"
                  onClick={() => editProjectDetail(dataItem?.id, false)}
                >
                  Edit
                </button>
              </li>
              {!isMintPaused ? (
                <li>
                  <button className="btn-gradient btn-gradient-after" onClick={handleShow}>
                    Pause
                  </button>
                </li>
              ) : (
                <li>
                  <button
                    className="btn-gradient btn-gradient-after"
                    onClick={() => {
                      handleResume(dataItem?.id);
                    }}
                  >
                    Resume
                  </button>
                </li>
              )}
            </ul>
          </div>
        )}
        <div className="launched-mint-content">
          <ul>
            {isDeploymentInProg ? (
              <>
                <div className="d-flex justify-content-center text-center my-5 lh-lg">
                  <p className="font-italic">
                    {`Deployment in progress...`}
                    <br />
                    It will take a few minutes before your project is ready to launch mint.
                  </p>
                </div>
                <div className="group-content-box mb-5">
                  <div className="min-height-100" />
                </div>
              </>
            ) : (
              <MintItem
                dataItem={dataItem}
                handleWithdraw={handleWithdraw}
                isWithdrawnBefore={isWithdrawnBefore}
                editProjectDetail={editProjectDetail}
                mintData={mintData}
              />
            )}
          </ul>
        </div>

        {isDeploymentInProg
          ? dataItem?.allowListGroup?.map((_, index) => (
              <div className="launched-mint-content" key={index}>
                <div className="group-content-box mb-4">
                  <div className="min-height-80" />
                </div>
              </div>
            ))
          : dataItem?.allowListGroup?.length > 0 && (
              <div className="launched-mint-content">
                <h2 className="mb-2"> Minting Groups </h2>
                <p>Use toggle to launch mint for a group.</p>
                <ul>
                  {dataItem?.allowListGroup?.map((item, index) => (
                    <LaunchedItem
                      key={index}
                      fetchData={fetchData}
                      groupItem={item}
                      dataItem={dataItem}
                      index={index}
                      getNoOfReg={getNoOfReg}
                      registrant={noOfReg.find((item1) => item1._id === item.id)}
                      contract={contract}
                      isMintPaused={isMintPaused}
                    />
                  ))}
                </ul>
              </div>
            )}

        {show && (
          <PauseConfirmation show={show} handleClose={handleClose} dataItem={dataItem} handlePause={handlePause} />
        )}

        {revealArtModal && (
          <RevealArtModal
            show={revealArtModal}
            handleClose={handleRevealArtClose}
            dataItem={dataItem}
            handleRevealArt={handleRevealArt}
          />
        )}
      </div>
    </ConfirmationDialogProvider>
  );
};

export default Launched;
