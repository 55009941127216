import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setLoader, setSkeleton } from "../../../../store/reducer";
import { ApiCall } from "../../../../utils/ApiUtils";
import ArrowRight from "../../../../assets/images/arrowRight.svg";
import DeploymentModal from "../../../home/components/DeploymentModal";
import { makeTransaction } from "../../../../web3.js/contractIngration";
import { errorMessages } from "../../../../utils/errorMessages";

const LaunchModal = ({ show, LaunchModalClose, launchData, fetchData }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [showDepMod, setShowDepMod] = useState(false);
  const [deploymentFee, setDeploymentFee] = useState({});
  const [cbSelect, setCbSelect] = useState(false);

  const handleClose = () => {
    setShowDepMod(false);
    setCbSelect(false);
  };

  const handleShow = async () => {
    try {
      dispatch(setSkeleton(true));
      setShowDepMod(true);
      const resp = await ApiCall("GET", `/rest/forge/getFees/${launchData.id}`);
      setDeploymentFee(resp);
      dispatch(setSkeleton(false));
    } catch (error) {
      console.log(error);
      dispatch(setSkeleton(false));
      toast.error("Something went wrong.");
    }
  };

  const launchDraft = async () => {
    try {
      dispatch(setLoader(true));
      await ApiCall("PUT", `/rest/forge/launch/${launchData.id}`, {
        allowListGroup: launchData.allowListGroup,
      });
      dispatch(setLoader(false));
      toast.success(`Forge launched!`);
      LaunchModalClose();
      fetchData();
      navigate("/manage");
    } catch (error) {
      dispatch(setLoader(false));
      console.log(error);
      toast.error(error?.response?.data?.message || "Something went wrong.");
    }
  };

  const handleFormSubmit = async (isDraft, signature) => {
    try {
      dispatch(setLoader(true));
      if (!isDraft) {
        await makeTransaction(launchData.id, signature);
        handleClose();
      }
      await launchDraft();
      dispatch(setLoader(false));
    } catch (error) {
      console.log("MAKE TRANSACTION ERROR: ", error);
      dispatch(setLoader(false));
      toast.error(errorMessages[error?.code] || error?.reason || "Something went wrong.");
    }
  };

  return (
    <>
      <Modal className="delete-confirmation" centered show={show} onHide={LaunchModalClose}>
        <Modal.Header closeButton className="border-0">
          <h4>Launch Confirmation</h4>
        </Modal.Header>
        <Modal.Body>
          <p>
            Are you sure you want to <span>Launch</span> this list?
          </p>
        </Modal.Body>
        <Modal.Footer className="justify-content-start border-0">
          <button onClick={handleShow} className="btn next-btn m-0">
            Launch
            <span className="d-flex">
              <img src={ArrowRight} alt="Icon" />
            </span>
          </button>
        </Modal.Footer>
      </Modal>
      {showDepMod && (
        <DeploymentModal
          show={showDepMod}
          handleClose={handleClose}
          handleFormSubmit={handleFormSubmit}
          fee={deploymentFee}
          cbSelect={cbSelect}
          setCbSelect={setCbSelect}
        />
      )}
    </>
  );
};

export default LaunchModal;
