import React from "react";
import { Modal } from "react-bootstrap";
import ArrowRight from "../../../../assets/images/arrowRight.svg";

const WithdrawConfirmation = ({ dataItem, show, handleClose, handleWithdraw, isWithdrawnBefore, editConfirm }) => {
  return (
    <Modal className="pause delete-confirmation" centered show={show} onHide={handleClose}>
      <Modal.Header closeButton className="border-0">
        <Modal.Title>Withdrawal Confirmation</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {isWithdrawnBefore ? (
          <p>
            Are you sure you want to initiate <span>withdrawal</span>?
          </p>
        ) : (
          <p>
            You cannot edit withdrawal wallets after the first <span>withdrawal</span>, are you sure you want to
            proceed?
          </p>
        )}
      </Modal.Body>
      <Modal.Footer className="justify-content-start border-0 gap-2">
        <button
          onClick={() => {
            handleWithdraw(dataItem);
            handleClose();
          }}
          className="btn next-btn m-0"
        >
          Yes
          <span className="d-flex">
            <img src={ArrowRight} alt="Icon" />
          </span>
        </button>
        {!isWithdrawnBefore && (
          <span
            onClick={() => {
              editConfirm();
            }}
            className="cursor-pointer text-light mr-2"
          >
            Edit Wallets
          </span>
        )}
      </Modal.Footer>
    </Modal>
  );
};

export default WithdrawConfirmation;
